import React from 'react'
import { Section, Container } from 'react-bulma-components'

// Components
import CaseStudyHero from 'components/case-study-hero'

function Clients3() {
  return (
    <Section>
      <Container>
        <CaseStudyHero
          className="has-text-info"
          logo="https://www.datocms-assets.com/20955/1579557662-bitmap.png?w=200"
          mainImage="https://www.datocms-assets.com/20955/1606945146-energenie-mockup.png?w=400&fm=jpg"
          titleHtml="Simplifying the use of <span class='has-text-success'>smarthome devices</span>"
          to="/case-studies/eneregenie"
          toText="Learn more"
        />
        <img
          src="https://www.datocms-assets.com/20955/1579120592-link.png?w=1800"
          alt=""
        />
        <CaseStudyHero
          className="has-text-success"
          flipped
          logo="https://www.datocms-assets.com/20955/1579120601-travelodge-logo.png"
          mainImage="https://www.datocms-assets.com/20955/1579120579-travelodge-mockup.png?w=400&fm=jpg"
          titleHtml="Prototyping <span class='has-text-success'>new customer experiences</span> in hotel booking."
          to="/case-studies/travelodge-prototypes"
        />
      </Container>
    </Section>
  )
}

export default Clients3
